import {NavListItem} from "@shared/components/default-header";
import {SupportedCountryCodes, SupportedLocales} from "@shared/constants";
import {t} from "@shared/i18n";

export const getDonateLinks = (localeSegment: string): NavListItem[] => [
    {dataElementId: "btn_nav_discover", link: `/discover`, text: t("Categories")},
    {
        dataElementId: "btn_nav_crisis_relief",
        forCountries: [SupportedCountryCodes.UNITED_STATES],
        link: `${localeSegment}/c/act`,
        text: t("Crisis relief"),
    },
    {
        dataElementId: "btn_nav_causes",
        forCountries: [SupportedCountryCodes.UNITED_STATES],
        link: `${localeSegment}/c/cause`,
        text: t("Social Impact Funds"),
    },
    {
        dataElementId: "btn_nav_supporter_space",
        forLocales: [SupportedLocales.ENGLISH_US],
        link: `${localeSegment}/c/supporter-space`,
        text: t("Supporter Space"),
    },
];

export const getFundraiseLinks = (localeSegment: string): NavListItem[] => [
    {
        dataElementId: "btn_nav_start",
        link: `${localeSegment}/c/start`,
        text: t("How to start a GoFundMe"),
    },
    {
        dataElementId: "btn_nav_start_fundraising_categories",
        forLocales: [
            SupportedLocales.ENGLISH_AU,
            SupportedLocales.ENGLISH_CA,
            SupportedLocales.ENGLISH_IE,
            SupportedLocales.ENGLISH_GB,
            SupportedLocales.ENGLISH_US,
            SupportedLocales.SPANISH_US,
        ],
        link: `${localeSegment}/c/start/fundraising-categories`,
        text: t("Fundraising categories"),
    },
    {
        dataElementId: "btn_nav_team_fundraising",
        link: `${localeSegment}/c/fundraising-tips/team`,
        text: t("Team fundraising"),
    },
    {dataElementId: "btn_nav_blog", link: `${localeSegment}/c/blog`, text: t("Fundraising Blog")},
    {
        dataElementId: "btn_nav_start_charity_fundraising",
        forLocales: [
            SupportedLocales.ENGLISH_AU,
            SupportedLocales.ENGLISH_CA,
            SupportedLocales.ENGLISH_IE,
            SupportedLocales.ENGLISH_GB,
            SupportedLocales.ENGLISH_US,
            SupportedLocales.SPANISH_US,
        ],
        link: `${localeSegment}/start/charity-fundraising`,
        text: t("Charity fundraising"),
    },
    {
        dataElementId: "btn_nav_charity",
        forLocales: [
            SupportedLocales.ENGLISH_AU,
            SupportedLocales.ENGLISH_CA,
            SupportedLocales.ENGLISH_IE,
            SupportedLocales.ENGLISH_GB,
            SupportedLocales.ENGLISH_US,
        ],
        link: `${localeSegment}/c/charity-fundraising`,
        text: t("Sign up as a charity"),
    },
    {
        forLocales: [SupportedLocales.ENGLISH_GB],
        link: `${localeSegment}/c/act/workplace-partnerships-business-fundraising`,
        text: t("Corporate fundraising"),
    },
    {
        forLocales: [SupportedLocales.ENGLISH_GB],
        link: `${localeSegment}/c/act/gofundme-event-partner`,
        text: t("Event fundraising"),
    },
];

export const getAboutLinks = ({
    countryCode,
    helpCenterLocale,
    localeSegment,
    supportUrlPrefix,
}: {
    countryCode: string;
    helpCenterLocale: string;
    localeSegment: string;
    supportUrlPrefix: string;
}): NavListItem[] => [
    {dataElementId: "btn_nav_how_it_works", link: `${localeSegment}/c/how-it-works`, text: t("How GoFundMe works")},
    {
        dataElementId: "btn_nav_gfm_guarantee",
        link: `${localeSegment}/c/safety/gofundme-guarantee`,
        text: t("GoFundMe Giving Guarantee"),
    },
    {
        dataElementId: "btn_nav_supported_countries",
        link: `${supportUrlPrefix}/articles/360001972748`,
        text: t("Supported countries"),
    },
    {dataElementId: "btn_nav_pricing", link: `${localeSegment}/c/pricing`, text: t("Pricing")},
    {dataElementId: "btn_nav_support_gofundme_com", link: supportUrlPrefix, text: t("Help Center")},
    {
        dataElementId: "btn_nav_about_gfm",
        link: `${localeSegment}/c/about-us`,
        text: countryCode === "US" ? t("About GoFundMe and Classy") : t("About GoFundMe"),
    },
    {
        dataElementId: "btn_nav_newsroom",
        link: `${localeSegment}/c/press`,
        text:
            // custom link text for German regulation compliance
            helpCenterLocale === "de-de"
                ? "Impressum und Pressestelle"
                : helpCenterLocale === "en-us"
                  ? t("Newsroom")
                  : t("Press Center"),
    },
    {
        dataElementId: "btn_nav_careers",
        link: `${localeSegment}/c/careers`,
        text: t("Careers"),
    },
    {
        dataElementId: "btn_nav_gfm_org",
        forCountries: [SupportedCountryCodes.UNITED_STATES],
        link: "https://www.gofundme.org",
        text: "GoFundMe.org",
    },
    {
        dataElementId: "btn_nav_gfm_org",
        forCountries: [SupportedCountryCodes.UNITED_KINGDOM],
        link: "https://gofundme.org.uk/",
        text: "GoFundMe.org",
    },
];

export const getMoreResourcesColumnLinks = (localeSegment: string): NavListItem[] => [
    {link: `${localeSegment}/c/fundraising-tips`, text: t("Fundraising tips")},
    {link: `${localeSegment}/c/fundraising-ideas`, text: t("Fundraising ideas")},
    {
        forLocales: [
            SupportedLocales.DUTCH_NL,
            SupportedLocales.ENGLISH_AU,
            SupportedLocales.ENGLISH_CA,
            SupportedLocales.ENGLISH_IE,
            SupportedLocales.ENGLISH_GB,
            SupportedLocales.ENGLISH_US,
            SupportedLocales.FRENCH_CA,
            SupportedLocales.FRENCH_FR,
            SupportedLocales.GERMAN_DE,
            SupportedLocales.ITALIAN_IT,
        ],
        link: `${localeSegment}/c/blog/emergency-rental-assistance`,
        text: t("Rent assistance"),
    },
    {
        forLocales: [
            SupportedLocales.DUTCH_NL,
            SupportedLocales.ENGLISH_AU,
            SupportedLocales.ENGLISH_CA,
            SupportedLocales.ENGLISH_IE,
            SupportedLocales.ENGLISH_GB,
            SupportedLocales.ENGLISH_US,
            SupportedLocales.FRENCH_CA,
            SupportedLocales.FRENCH_FR,
            SupportedLocales.GERMAN_DE,
            SupportedLocales.ITALIAN_IT,
            SupportedLocales.PORTUGUESE_PT,
        ],
        link: `${localeSegment}/c/blog/charity-fundraising-sites`,
        text: t("Fundraising sites"),
    },
    {
        link: `${localeSegment}/c/fundraising-ideas/team`,
        text: t("Team fundraising ideas"),
        forLocales: [
            SupportedLocales.ENGLISH_AU,
            SupportedLocales.ENGLISH_CA,
            SupportedLocales.ENGLISH_GB,
            SupportedLocales.ENGLISH_US,
        ],
    },
    {link: `${localeSegment}/c/crowdfunding`, text: t("What is crowdfunding?")},
    {link: `${localeSegment}/c/why-gofundme`, text: t("Why GoFundMe")},
    {link: `${localeSegment}/c/questions`, text: t("Common questions")},
    {link: `${localeSegment}/c/success`, text: t("Success stories")},
    {
        forLocales: [
            SupportedLocales.DUTCH_NL,
            SupportedLocales.ENGLISH_AU,
            SupportedLocales.ENGLISH_CA,
            SupportedLocales.ENGLISH_IE,
            SupportedLocales.ENGLISH_GB,
            SupportedLocales.ENGLISH_US,
            SupportedLocales.FRENCH_FR,
            SupportedLocales.GERMAN_DE,
            SupportedLocales.ITALIAN_IT,
            SupportedLocales.PORTUGUESE_PT,
        ],
        link: `${localeSegment}/c/blog/how-to-get-help-with-bills`,
        text: t("Help with bills"),
    },
    {link: `${localeSegment}/c/blog/pay-medical-bills`, text: t("Help with medical bills")},
    {
        link: `${localeSegment}/c/fundraising-ideas/college`,
        text: t("Fundraising ideas for college"),
        forLocales: [SupportedLocales.ENGLISH_US],
    },
    {link: `${localeSegment}/c/fundraising-ideas/schools`, text: t("School fundraising ideas")},
    {
        forLocales: [
            SupportedLocales.DUTCH_NL,
            SupportedLocales.ENGLISH_AU,
            SupportedLocales.ENGLISH_CA,
            SupportedLocales.ENGLISH_IE,
            SupportedLocales.ENGLISH_GB,
            SupportedLocales.ENGLISH_US,
            SupportedLocales.FRENCH_CA,
            SupportedLocales.FRENCH_FR,
            SupportedLocales.GERMAN_DE,
            SupportedLocales.PORTUGUESE_PT,
            SupportedLocales.SPANISH_ES,
            SupportedLocales.SPANISH_MX,
            SupportedLocales.SPANISH_US,
        ],
        link: `${localeSegment}/c/blog/service-dog-fundraising`,
        text: t("How to get a service dog"),
    },
    {link: `${localeSegment}/c/blog/top-crowdfunding-sites`, text: t("Crowdfunding sites")},
    {
        link: `${localeSegment}/c/blog/financial-help-for-veterans`,
        text: t("Help for veterans"),
        forLocales: [
            SupportedLocales.ENGLISH_AU,
            SupportedLocales.ENGLISH_CA,
            SupportedLocales.ENGLISH_IE,
            SupportedLocales.ENGLISH_GB,
            SupportedLocales.ENGLISH_US,
            SupportedLocales.GERMAN_DE,
        ],
    },
];
