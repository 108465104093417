import {SelectHTMLAttributes, useEffect, useState} from "react";
import dynamic from "next/dynamic";
import {useInView} from "react-intersection-observer";

import {CircleFlag, circleFlagsType, SecondaryButton, Toggle} from "@design/system";

import {
    getLocaleDropdownCountries,
    getLocaleDropdownLanguages,
    SupportedCountryCodes,
    SupportedLanguageCodes,
    SupportedLocales,
} from "@shared/constants";
import {t} from "@shared/i18n";
import {extractCountryCodeFromLocale, extractLangCodeFromLocale, getSupportedLocale} from "@shared/utils/locale";

import type {LocaleChangeCallback} from "./types";

import styles from "./locale-picker.module.scss";

const LocalePickerModal = dynamic(() => import("./locale-picker-modal").then((mod) => mod.LocalePickerModal), {
    ssr: false,
});

interface LocalePickerProps {
    locale?: SupportedLocales;
    onLocaleChange?: LocaleChangeCallback;
}

interface SelectOptionProps extends SelectHTMLAttributes<HTMLSelectElement> {
    text: string;
    value: string | number;
}

function LocalePicker(props: LocalePickerProps) {
    const {ref, inView} = useInView({threshold: 0});
    const {locale, onLocaleChange} = props;
    const localeDropdownCountries = getLocaleDropdownCountries();

    const [supportedLocale, setSupportedLocale] = useState<SupportedLocales | undefined>();
    const [languageCode, setLanguageCode] = useState<SupportedLanguageCodes | undefined>();
    const [countryCode, setCountryCode] = useState<SupportedCountryCodes | undefined>();

    useEffect(() => {
        setSupportedLocale(getSupportedLocale(locale) as SupportedLocales);
    }, [locale]);

    useEffect(() => {
        setCountryCode(extractCountryCodeFromLocale(supportedLocale));
        setLanguageCode(extractLangCodeFromLocale(supportedLocale));
    }, [supportedLocale]);

    const getLocaleItemName = (itemType: "country" | "language", code: string): string => {
        const dropdownList: SelectOptionProps[] =
            itemType === "country" ? localeDropdownCountries : getLocaleDropdownLanguages();
        const localeItem: SelectOptionProps | undefined = dropdownList.find((item) => (item.value as string) === code);
        return (
            localeItem?.text ||
            getLocaleItemName(
                itemType,
                itemType === "country" ? SupportedCountryCodes.UNITED_STATES : SupportedLanguageCodes.ENGLISH,
            )
        );
    };

    const circleFlagName = countryCode ? (countryCode.toLowerCase() as circleFlagsType) : null;
    const country = countryCode && getLocaleItemName("country", countryCode);
    const language = languageCode && getLocaleItemName("language", languageCode);

    return (
        (countryCode && languageCode && (
            <Toggle>
                {({on, setOn, setOff}) => (
                    <>
                        <SecondaryButton
                            ref={ref}
                            aria-label={t("Choose your language - {{country}}·{{language}}", {
                                country,
                                language,
                            })}
                            as="button"
                            className={styles.localePickerButton}
                            data-element-id="footer-language-picker"
                            onClick={setOn}
                            size="small"
                            title={t("Choose your language")}
                            variant="default"
                        >
                            {circleFlagName && <CircleFlag size="small" name={circleFlagName} className="hrt-mr-1" />}
                            {country}
                            <span className="hrt-mr-1 hrt-ml-1">&#183;</span>
                            {language}
                        </SecondaryButton>

                        {inView && (
                            <LocalePickerModal
                                className="m-locale-picker-modal"
                                countryCode={countryCode}
                                languageCode={languageCode}
                                isOpen={on}
                                onClose={setOff}
                                onLocaleChange={onLocaleChange}
                            />
                        )}
                    </>
                )}
            </Toggle>
        )) ||
        null
    );
}

export default LocalePicker;
