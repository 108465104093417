import {useEffect, useState} from "react";
import classNames from "classnames";

import {
    BrandIcon,
    Footer,
    FooterLegal,
    FooterMain,
    FooterSecondary,
    FooterSocial,
    Link,
    NavListItem as NavListItemLink,
    TertiaryButton,
    TertiaryIconButton,
    Text,
} from "@design/system";

import {NavListItem} from "@shared/components/default-header";
import type {LocaleChangeCallback} from "@shared/components/locale-picker/types";
import {SupportedCountryCodes, SupportedLocales} from "@shared/constants";
import {useGlobalContext} from "@shared/context/global-context";
import {useSupportUrlPrefix} from "@shared/hooks";
import {t} from "@shared/i18n";
import {extractLangCodeFromLocale, getHelpCenterLocale} from "@shared/utils/locale";

import {AppStoreType, getAppStoreImageUrl, getLocaleSegments} from "../helpers";
import {LocalePicker} from "../locale-picker";
import {getAboutLinks, getDonateLinks, getFundraiseLinks, getMoreResourcesColumnLinks} from "./default-footer-lists";

import styles from "./default-footer.module.scss";

interface Props {
    className?: string;
    onLocaleChange?: LocaleChangeCallback;
}

function DefaultFooter({className, onLocaleChange}: Props) {
    const [showAppStoreButton, setShowAppStoreButton] = useState(false);
    const [showPlayStoreButton, setShowPlayStoreButton] = useState(false);
    const {countryCode, locale} = useGlobalContext();
    const supportUrlPrefix = useSupportUrlPrefix();

    const isUnitedStates = countryCode === SupportedCountryCodes.UNITED_STATES;
    const languageCode = extractLangCodeFromLocale(locale).replace("en", "");

    const [localeSegment, setLocaleSegment] = useState("");
    const [helpCenterLocale, sethelpCenterLocale] = useState("en-us");

    const countryCodeFilter = ({forCountries}: NavListItem) => !forCountries || forCountries.includes(countryCode);
    const localeFilter = ({forLocales}: NavListItem) => !forLocales || forLocales.includes(locale);

    useEffect(() => {
        setLocaleSegment(getLocaleSegments(locale as SupportedLocales).localeSegment);
        sethelpCenterLocale(getHelpCenterLocale(locale as SupportedLocales));
    }, [locale]);

    useEffect(() => {
        if (navigator.userAgent.match(/Android/i)) {
            setShowPlayStoreButton(true);
        } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
            setShowAppStoreButton(true);
        } else {
            setShowAppStoreButton(true);
            setShowPlayStoreButton(true);
        }
    }, []);

    const footerClasses = classNames(className, styles.defaultFooter, "shared-default-footer");

    return (
        <Footer className={footerClasses} data-tracking-namespace="footer">
            <FooterMain>
                <Text as="h2" screenReaderOnly>
                    {t("Secondary menu")}
                </Text>
                <FooterMain.Column title={t("Donate")}>
                    {getDonateLinks(localeSegment)
                        .filter(countryCodeFilter)
                        .filter(localeFilter)
                        .map(({link, text, dataElementId}) => (
                            <NavListItemLink
                                key={text}
                                as="a"
                                data-element-id={dataElementId}
                                href={link ?? ""}
                                size="condensed"
                                title={text}
                            />
                        ))}
                </FooterMain.Column>
                <FooterMain.Column title={t("Fundraise")}>
                    {getFundraiseLinks(localeSegment)
                        .filter(localeFilter)
                        .map(({link, text, dataElementId}) => (
                            <NavListItemLink
                                key={text}
                                as="a"
                                data-element-id={dataElementId}
                                href={link ?? ""}
                                size="condensed"
                                title={text}
                            />
                        ))}
                </FooterMain.Column>
                <FooterMain.Column
                    className={locale !== SupportedLocales.FRENCH_FR ? "hrt-footer-multicolumn" : ""}
                    title={t("About")}
                >
                    {getAboutLinks({
                        countryCode,
                        helpCenterLocale,
                        localeSegment,
                        supportUrlPrefix,
                    })
                        .filter(countryCodeFilter)
                        .map(({link, text, dataElementId}) => (
                            <NavListItemLink
                                key={text}
                                as="a"
                                data-element-id={dataElementId}
                                href={link ?? ""}
                                size="condensed"
                                title={text}
                            />
                        ))}
                </FooterMain.Column>

                {locale === SupportedLocales.FRENCH_FR && (
                    <div className="hrt-px-1">
                        <img src="/nextassets/shared/logo-ifp.png" alt="" width={64} height={64} />
                        <p className="hrt-text-body-sm hrt-mt-1">
                            GoFundMe Ireland Limited est immatriculée à l’ORIAS en tant qu’Intermédiaire en Financement
                            Partificipatif (IFP) sous le numéro d’immatriculation 24000751.
                        </p>
                    </div>
                )}

                <FooterMain.MoreResources title={t("More resources")}>
                    {getMoreResourcesColumnLinks(localeSegment)
                        .filter(localeFilter)
                        .map(({link, text, dataElementId}) => (
                            <NavListItemLink
                                key={text}
                                as="a"
                                data-element-id={dataElementId}
                                href={link ?? ""}
                                size="condensed"
                                title={text}
                            />
                        ))}
                </FooterMain.MoreResources>
            </FooterMain>

            <FooterSecondary className="hrt-footer-secondary--default-areas">
                <div className="shared-default-footer-locale">
                    <LocalePicker locale={locale} onLocaleChange={onLocaleChange} />
                </div>

                <FooterSocial>
                    <TertiaryIconButton
                        as="a"
                        buttonLabel="Facebook"
                        href="https://www.facebook.com/gofundme"
                        size="medium"
                    >
                        <BrandIcon name="facebook" size="large" />
                    </TertiaryIconButton>
                    <TertiaryIconButton
                        as="a"
                        buttonLabel="YouTube"
                        href="https://www.youtube.com/user/gofundme"
                        size="medium"
                    >
                        <BrandIcon name="youtube" size="large" />
                    </TertiaryIconButton>
                    <TertiaryIconButton as="a" buttonLabel="Twitter" href="https://twitter.com/gofundme" size="medium">
                        <BrandIcon name="x" size="large" />
                    </TertiaryIconButton>
                    <TertiaryIconButton
                        as="a"
                        buttonLabel="Instagram"
                        href="https://www.instagram.com/gofundme/"
                        size="medium"
                    >
                        <BrandIcon name="instagram" size="large" />
                    </TertiaryIconButton>
                    <TertiaryIconButton
                        as="a"
                        buttonLabel="Medium"
                        href="https://www.medium.com/gofundme-stories/"
                        size="medium"
                    >
                        <BrandIcon name="medium" size="large" />
                    </TertiaryIconButton>
                    <TertiaryIconButton
                        as="a"
                        buttonLabel="Podcast"
                        href="https://podcasts.apple.com/us/podcast/true-stories-of-good-people/id1389955443"
                        size="medium"
                    >
                        <BrandIcon name="podcast" size="large" />
                    </TertiaryIconButton>
                </FooterSocial>

                <FooterLegal>
                    <span
                        className="shared-default-footer-legal-date hrt-text-neutral-500 hrt-mx-1"
                        data-chromatic="ignore"
                    >
                        &copy; 2010-{new Date().getFullYear()}
                        <> </>
                        GoFundMe
                    </span>
                    <TertiaryButton
                        as="a"
                        className="hrt-footer-legal-item hrt-text-left"
                        href={`${localeSegment}/c/terms`}
                    >
                        {t("Terms")}
                    </TertiaryButton>
                    <TertiaryButton
                        as="a"
                        className="hrt-footer-legal-item hrt-text-left"
                        href={`${localeSegment}/c/privacy`}
                    >
                        {t("Privacy Notice")}
                    </TertiaryButton>
                    <TertiaryButton
                        as="a"
                        className="hrt-footer-legal-item hrt-text-left"
                        href={
                            countryCode === SupportedCountryCodes.UNITED_STATES
                                ? "/c/legal"
                                : `${localeSegment}/c/legal-2`
                        }
                    >
                        {t("Legal")}
                    </TertiaryButton>
                    {isUnitedStates && (
                        <TertiaryButton
                            as="a"
                            className="hrt-footer-legal-item hrt-text-left"
                            href={`${localeSegment}/c/accessibility-statement`}
                        >
                            {t("Accessibility Statement")}
                        </TertiaryButton>
                    )}
                    {(locale === SupportedLocales.ENGLISH_US ||
                        locale === SupportedLocales.ENGLISH_GB ||
                        locale === SupportedLocales.ENGLISH_CA ||
                        locale === SupportedLocales.ENGLISH_IE) && (
                        <TertiaryButton
                            as="a"
                            className="hrt-footer-legal-item hrt-text-left"
                            href={`${localeSegment}/c/gofundme-cookie-policy`}
                        >
                            {t("Cookie Policy")}
                        </TertiaryButton>
                    )}
                    <TertiaryButton
                        as="a"
                        className="hrt-footer-legal-item hrt-text-left"
                        href={`${localeSegment}/c/opt-out-rights`}
                    >
                        {t("Your Privacy Choices")}
                        <img
                            alt=""
                            className="hrt-ml-1"
                            height="14"
                            src="/nextassets/shared/privacy-pill.png"
                            width="29"
                        />
                    </TertiaryButton>
                </FooterLegal>

                <div className="shared-default-footer-stores">
                    {showPlayStoreButton && (
                        <Link
                            href="https://play.google.com/store/apps/details?id=com.GoFundMe.GoFundMe&referrer=utm_source%3Dgofundme%26utm_medium%3Dpublic_web_mobileapp"
                            className="shared-default-footer-store-button shared-default-footer-store-button--google"
                            data-testid="android-upsell-btn"
                            data-gfm-analytics-element="btn_mobiledashboard_nativeappupsell_getapp_android"
                            style={{
                                backgroundImage: `url(${getAppStoreImageUrl(AppStoreType.Google, languageCode)})`,
                            }}
                            unstyled={true}
                        >
                            {t("Get it on Google Play")}
                        </Link>
                    )}
                    {showAppStoreButton && (
                        <Link
                            href="https://itunes.apple.com/app/apple-store/id734130700?pt=2179020&ct=gofundme_public_web_mobileapp&mt=8"
                            className={`shared-default-footer-store-button shared-default-footer-store-button--apple shared-default-footer-store-button--${languageCode}`}
                            data-testid="apple-upsell-btn"
                            data-gfm-analytics-element="btn_mobiledashboard_nativeappupsell_getapp_iphone"
                            style={{
                                backgroundImage: `url(${getAppStoreImageUrl(AppStoreType.Apple, languageCode)})`,
                            }}
                            unstyled={true}
                        >
                            {t("Available on the App Store")}
                        </Link>
                    )}
                </div>
            </FooterSecondary>
        </Footer>
    );
}

export default DefaultFooter;
